<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="小区编号">
                <a-input placeholder="请输入小区编号" v-model="queryParam.keyId" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="小区名称">
                <a-input placeholder="请输入小区名称" v-model="queryParam.communityName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="区域">
                <a-cascader
                  :options="regionList"
                  placeholder="省-市-区(县)"
                  :field-names="{label: 'label', value: 'value', children: 'children' }"
                  :value="defaultRegion"
                  @change="changeRegion"
                />
              </a-form-item>
            </a-col>
            <!--a-col :md="8" :sm="24">
              <a-form-item label="区域">
                <a-select
                  style="width: 120px"
                  v-model="queryParam.provinceId"
                  placeholder="请选择省份"
                  :options="provinceList"
                  @change="cityListApi"
                >
                </a-select>
                <a-select
                  style="width: 120px"
                  v-model="queryParam.cityId"
                  placeholder="请选择城市"
                  :options="cityList"
                  @change="areaListApi"
                >
                </a-select>
                <a-select
                  style="width: 120px"
                  v-model="queryParam.areaId"
                  placeholder="请选择区域"
                  :options="areaList"
                >
                </a-select>
              </a-form-item>
            </a-col-->
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">添加</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="gotoCodeInfo(record)">小区二维码</a>
          <a-divider type="vertical" />
          <a @click="gotoWxCodeInfo(record)">微信二维码</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="gotoBuilding(record.id)">查看楼宇</a>
          <a-divider type="vertical" />
          <a @click="gotoUnit(record.id)">查看单元</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item><a @click="gotoHouse(record.id)">查看房屋</a></a-menu-item>
              <a-menu-item><a @click="gotoResident(record.id)">查看住户</a></a-menu-item>
            </a-menu>
            <a>更多<a-icon type="down"/></a>
          </a-dropdown>
        </span>
      </s-table>
      <a-modal
        title="添加/编辑小区"
        :width="800"
        :destroyOnClose="true"
        v-model="visible"
        @ok="saveCommunityApi"
      >
        <a-form :form="form">
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="区域"
            :validateStatus="checkParams.areaCodeStatus"
            :help="checkParams.areaCodeStatusMsg"
          >
            <a-cascader
              :allowClear="false"
              style="width:auto"
              :options="regionList"
              placeholder="省-市-区(县)"
              :field-names="{label: 'label', value: 'value', children: 'children' }"
              @change="changeAddRegion"
              v-decorator="['areaCode', {initialValue: addDefaultRegion, rules: [{required: true}]}]"
            />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="小区名称"
            :validateStatus="checkParams.communityNameStatus"
            :help="checkParams.communityNameStatusMsg"
          >
            <a-input
              placeholder="请输入小区名称"
              v-decorator="['communityName', {initialValue: mdl.communityName, rules: [{required: true, validator: verifyCommunityName, validateTrigger: 'change'}]}]"
            />
          </a-form-item>
          <!--a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="区域"
            :validateStatus="checkParams.areaCodeStatus"
            :help="checkParams.areaCodeStatusMsg"
          >
            <a-select
              v-model="mdl.provinceId"
              style="width: 120px"
              placeholder="请选择省份"
              :options="provinceList"
              @change="cityListInfoApi"
            >
            </a-select>
            <a-select
              style="width: 120px"
              v-model="mdl.cityId"
              placeholder="请选择城市"
              :options="cityListInfo"
              @change="areaListInfoApi"
            >
            </a-select>
            <a-select
              style="width: 120px"
              v-model="mdl.areaCode"
              placeholder="请选择区域"
              :options="areaListInfo"
            >
            </a-select>
          </a-form-item-->
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="备注"
          >
            <a-textarea :rows="5" v-model="mdl.desc" placeholder="备注" />
          </a-form-item>
        </a-form>
      </a-modal>
      <see-qrcode ref="seeqrcode"></see-qrcode>
      <see-wxqrcode ref="seewxqrcode"></see-wxqrcode>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import { checkCommunity, communityList, saveCommunity } from '@/api/community'
import { areaList, cityList, provinceList, regionList } from '@/api/region'
import SeeQrcode from '@/views/home/components/SeeQrcode'
import SeeWxqrcode from '@/views/home/components/SeeWxqrcode'
const columns = [
  {
    title: '小区编号',
    dataIndex: 'keyId'
  },
  {
    title: '小区名称',
    dataIndex: 'communityName'
  },
  {
    title: '区域',
    dataIndex: 'areaCodeName'
  },
  {
    title: '添加时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '440px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'CommunityList',
  components: {
    STable,
    Ellipsis,
    STree,
    SeeQrcode,
    SeeWxqrcode
  },
  data () {
    this.columns = columns
    return {
      description: '',
      visible: false,
      form: this.$form.createForm(this, { name: 'addRoleFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        keyId: '',
        communityName: '',
        provinceId: '请选择省份',
        cityId: '请选择城市',
        areaId: '请选择区域'
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return communityList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      selectedRowKeys: [],
      selectedRows: [],
      provinceList: [],
      cityList: [],
      areaList: [],
      cityListInfo: [],
      areaListInfo: [],
      checkParams: {
        communityNameStatus: 'success',
        communityNameStatusMsg: null,
        isCommunityName: false,
        areaCodeStatus: 'success',
        areaCodeStatusMsg: null,
        isAreaCode: false
      },
      dataParams: {
        communityName: ''
      },
      regionList: [],
      defaultRegion: [],
      addDefaultRegion: []
    }
  },
  filters: {
  },
  created () {
    // this.provinceListApi()
    this.regionListApi()
  },
  watch: {
    $route (val) {
      if (val.name === 'communityInfoManage') {
        this.queryParam = {
          keyId: '',
          communityName: '',
          provinceId: '请选择省份',
          cityId: '请选择城市',
          areaId: '请选择区域'
        }
        this.regionListApi()
        this.$refs.table.refresh(true)
      }
    }
  },
  methods: {
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    provinceListApi () {
      const _this = this
      provinceList().then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.provinceList = res.result
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    cityListApi (e) {
      const _this = this
      console.log('cityListApi', e)
      _this.queryParam.cityId = '请选择城市'
      _this.queryParam.areaId = '请选择区域'
      cityList({ provinceId: e }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.cityList = res.result
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    areaListApi (e) {
      const _this = this
      console.log('areaListApi', e)
      _this.queryParam.areaId = '请选择区域'
      areaList({ cityId: e }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.areaList = res.result
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    cityListInfoApi (e) {
      const _this = this
      console.log('cityListApi', e)
      cityList({ provinceId: e }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.cityListInfo = res.result
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    areaListInfoApi (e) {
      const _this = this
      console.log('areaListApi', e)
      areaList({ cityId: e }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.areaListInfo = res.result
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    verifyCommunityName (rule, value, callback) {
      const _this = this
      _this.mdl.communityName = value.trim()
      if (_this.mdl.communityName !== '') {
        _this.checkParams.communityNameStatus = 'success'
        _this.checkParams.communityNameStatusMsg = null
        _this.checkParams.isCommunityName = true
      } else {
        _this.checkParams.communityNameStatus = 'error'
        _this.checkParams.communityNameStatusMsg = '请输入小区名称'
        _this.checkParams.isCommunityName = false
        callback(new Error('请输入小区名称'))
      }
    },
    resetSearchForm () {
      this.defaultRegion = []
      this.queryParam = {
        keyId: '',
        communityName: '',
        provinceId: '请选择省份',
        cityId: '请选择城市',
        areaId: '请选择区域'
      }
    },
    saveCommunityApi () {
      const _this = this
      _this.dataParams.keyId = _this.mdl.keyId ? _this.mdl.keyId : 0
      _this.dataParams.communityName = _this.mdl.communityName
      _this.dataParams.areaCode = _this.mdl.areaCode
      _this.dataParams.desc = _this.mdl.desc
      console.log(_this.dataParams)
      if (_this.dataParams.areaCode === '' || _this.dataParams.areaCode === 0 || _this.dataParams.areaCode === '请选择区域') {
        _this.checkParams.areaCodeStatus = 'error'
        _this.checkParams.areaCodeStatusMsg = '请选择区域'
        _this.checkParams.isAreaCode = false
        return false
      } else {
        _this.checkParams.areaCodeStatus = 'success'
        _this.checkParams.areaCodeStatusMsg = null
        _this.checkParams.isAreaCode = true
      }

      if (_this.dataParams.communityName === '') {
        _this.checkParams.communityNameStatus = 'error'
        _this.checkParams.communityNameStatusMsg = '请输入小区名称'
        _this.checkParams.isCommunityName = false
        return false
      } else {
        _this.checkParams.communityNameStatus = 'success'
        _this.checkParams.communityNameStatusMsg = null
        _this.checkParams.isCommunityName = true
      }

      checkCommunity(_this.dataParams).then((resc) => {
        console.log('checkCommunity', resc)
        if (resc.errorCode === 0) {
          saveCommunity(_this.dataParams).then((res) => {
            if (res.errorCode === 0) {
              if (_this.dataParams.keyId > 0) {
                _this.$message.success('更新成功')
              } else {
                _this.$message.success('添加成功')
              }
              _this.visible = false
              _this.$refs.table.refresh()
            } else {
              _this.$message.error(res.errorMsg)
            }
          }).catch((err) => {
            console.log(err)
          })
        } else {
          _this.checkParams.communityNameStatus = 'error'
          _this.checkParams.communityNameStatusMsg = resc.errorMsg
          _this.checkParams.isCommunityName = false
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    handleAdd () {
      this.mdl = {
        keyId: 0,
        id: '',
        communityName: '',
        desc: '',
        // provinceId: '请选择省份',
        // cityId: '请选择城市',
        // areaCode: '请选择区域'
        provinceId: 0,
        cityId: 0,
        areaCode: 0
      }
      this.checkParams.areaCodeStatus = 'success'
      this.checkParams.areaCodeStatusMsg = null
      this.checkParams.isAreaCode = false
      this.checkParams.communityNameStatus = 'success'
      this.checkParams.communityNameStatusMsg = null
      this.checkParams.isCommunityName = false
      this.addDefaultRegion = []
      this.visible = true
    },
    handleEdit (record) {
      // this.cityListInfoApi(record.provinceId)
      // this.areaListInfoApi(record.cityId)
      this.mdl = Object.assign({}, record)
      this.addDefaultRegion = [record.provinceId, record.cityId, record.areaCode]
      console.log(this.mdl)
      this.checkParams.areaCodeStatus = 'success'
      this.checkParams.areaCodeStatusMsg = null
      this.checkParams.isAreaCode = false
      this.checkParams.communityNameStatus = 'success'
      this.checkParams.communityNameStatusMsg = null
      this.checkParams.isCommunityName = false
      this.visible = true
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    onChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    changeRegion (e) {
      console.log('changeRegion', e)
      this.defaultRegion = e
      this.queryParam.provinceId = e[0] ? e[0] : 0
      this.queryParam.cityId = e[1] ? e[1] : 0
      this.queryParam.areaId = e[2] ? e[2] : 0
    },
    changeAddRegion (e) {
      this.addDefaultRegion = e
      this.mdl.provinceId = e[0] ? e[0] : 0
      this.mdl.cityId = e[1] ? e[1] : 0
      this.mdl.areaCode = e[2] ? e[2] : 0

      if (this.mdl.areaCode === '' || this.mdl.areaCode === 0) {
        this.checkParams.areaCodeStatus = 'error'
        this.checkParams.areaCodeStatusMsg = '请选择区域'
        this.checkParams.isAreaCode = false
        return false
      } else {
        this.checkParams.areaCodeStatus = 'success'
        this.checkParams.areaCodeStatusMsg = null
        this.checkParams.isAreaCode = true
      }
    },
    gotoBuilding (communityId) {
      this.$router.push({ path: '/home/building-list?communityId=' + communityId })
    },
    gotoUnit (communityId) {
      this.$router.push({ path: '/home/unit-list?communityId=' + communityId })
    },
    gotoHouse (communityId) {
      this.$router.push({ path: '/home/house-list?communityId=' + communityId })
    },
    gotoResident (communityId) {
      this.$router.push({ path: '/resident/resident-list?communityId=' + communityId })
    },
    gotoCodeInfo (data) {
      this.$refs.seeqrcode.ininData(data)
    },
    gotoWxCodeInfo (data) {
      this.$refs.seewxqrcode.ininData(data)
    }
  }
}
</script>
